import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <h2>
        Hello! I'm Nichole, a mobile engineer &#38; engineering manager in Palo
        Alto, California
      </h2>
      <p>
        I'm currently an <b>Engineering Manager</b> at{" "}
        <a href="http://tinder.com/">Tinder</a>, leading a team of iOS and
        Android engineers on the User Growth team.
      </p>
      <p>
        I worked as an iOS engineer at <a href="http://tinder.com/">Tinder</a>,
        and before that led the iOS team at{" "}
        <a href="http://goodreads.com">Goodreads</a>.
      </p>
      <p>
        Recently, I've been getting into React, Node, and other web
        technologies. I'm also a voracious reader, traveler, and
        Japanese-learner.
      </p>
      <p>
        Want to get in touch? <Link to="/contact">Contact me</Link>
      </p>
    </Layout>
  )
}

export default IndexPage
